import React from "react";
import { CSSTransition } from "react-transition-group";
// import DropdownMega from "./DropdownMega";
import DropdownItem from "./DropdownItem";
import { Link } from "react-router-dom";
import item from '../../../data/Navbar/nav-pages.json'

const NavbarDropdownMiddle = ({ data, collapse }) => {
  return (
    <CSSTransition in={collapse} timeout={300} classNames="collapse-navbar">
      <div className="collapse navbar-collapse" id="navbar-menu">
        <div className="col-half left">
          <ul
            className="nav navbar-nav navbar-center navbar-left"
            data-in="fadeIn"
            data-out="fadeOut"
          >
           <li className="megamenu-fw" >
            <Link
            to="/"
             className="dropdown-toggle"
        data-toggle="dropdown"
            >
            Home
            </Link>
           </li>

          
             <DropdownItem
                    item={item.subMenu}
                    title={item.title}
                    key={item.id}
                  />
           
           <li className="megamenu-fw" >
            <Link
            to="/blog-masonry"
             className="dropdown-toggle"
        data-toggle="dropdown"
            >
            Blog
            </Link>
           </li>

          </ul>
        </div>
        <div className="col-half right">
          <ul
            className="nav navbar-nav navbar-center navbar-right"
            data-in="fadeIn"
            data-out="fadeOut"
          >
          <li className="megamenu-fw" >
            <Link
            to="/grid-space-3-columns"
             className="dropdown-toggle"
        data-toggle="dropdown"
            >
            Portfolio
            </Link>
           </li>

           <li className="megamenu-fw" >
            <Link
            to=""
             className="dropdown-toggle"
        data-toggle="dropdown"
            >
            Shop
            </Link>
           </li>

{/* 
           <li className="megamenu-fw" >
            <Link>
            Home
            </Link>
           </li> */}
          </ul>
        </div>
      </div>
    </CSSTransition>
  );
};

export default NavbarDropdownMiddle;
