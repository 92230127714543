import React from "react";
// import logoWhite from "../../../assets/images/logo-white.png";
// import logoBlack from "../../../assets/images/logo-black.png";

const NavbarHeader = ({ type, fixed, width, handleCollapse, collapse }) => (
  <div className="navbar-header">
    <button
      type="button"
      className="navbar-toggle"
      data-toggle="collapse"
      data-target="#navbar-menu"
      onClick={handleCollapse}
    >
      <i
        className={
          "eicon ion-android-" + (collapse === true ? "close" : "menu")
        }
      ></i>
    </button>
    <div className="logo">
      <a href={process.env.PUBLIC_URL}>
        {!fixed && width > 1023 ? (
          // <img
          //   className="logo logo-display"
          //   src={type === "dark" ? logoBlack : logoWhite}
          //   alt=""
          // />
          <h1 style={{
            fontSize:'20px',
            fontWeight:'bolder',
            color:'white',
            margin:'0'
          }}>Happy Homes</h1>
        ) : !fixed && width < 1023 && type !== "dark" ? (
          <h1 style={{
            fontSize:'20px',
            fontWeight:'bolder',
            color:'black',
            margin:'0'
          }}>Happy Homes</h1>
        ) : (
          <h1 style={{
            fontSize:'20px',
            fontWeight:'bolder',
            color:'black',
            margin:'0'
          }}>Happy Homes</h1>
        )}
      </a>
    </div>
  </div>
);

export default NavbarHeader;
