import React from "react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-main-data.json";
import dataPortfolio from "../../data/Portfolio/portfolio-eight-data.json";
// Images
// import imgBg from "../../assets/images/slides/title-bg-10.jpg";
// Components
import NavbarSocial from "../../components/Navbar/NavbarSocial";
// import PageTitleThree from "../../components/PageTitle/PageTitleThree";
import PageTitle from "../../components/PageTitle/PageTitle";
import FooterTwo from "../../components/Footer/FooterTwo";
import PortfolioGrid from "../../components/Portfolio/PortfolioGrid";
import Loader from "../../components/Loader/Loader";

const PortfolioGridSpaceThree = () => (
  <Loader>
    <NavbarSocial data={dataNavbar} type="white" />
    <PageTitle
      title="Our Portfolio"
      tagline="Recent Works"
      image={'https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'}
    />
    <PortfolioGrid data={dataPortfolio} cols="3" spacing items="9" />
    <FooterTwo />
  </Loader>
);

export default PortfolioGridSpaceThree;
