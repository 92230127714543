import React from "react";

const PageTitleThree = ({ title, tagline, image }) => (
  <section
    className="title-hero-bg parallax-effect"
    style={{ backgroundImage: `url(https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80)` }}
  >
    <div className="parallax-overlay"></div>
    <div className="container">
      <div className="page-title text-center white-color">
        <h1>{title}</h1>
        <h4 className="text-uppercase mt-30">{tagline}</h4>
      </div>
    </div>
  </section>
);

export default PageTitleThree;
